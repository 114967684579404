import React from 'react'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import media from '../components/common/mediaQuery'
import { H2, Type, Caption } from '../components/common/type'
import { Anchor } from '../components/common/anchor'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <LeadContent>
      <Meta>
        <H2 as="h1">Oneil Olonade</H2>
      </Meta>
      <Meta>
        <Type as="h2">Web Engineer / Manager</Type>
        <Caption>
          <Anchor href="mailto:hello@oneilolonade.com">hello@oneilolonade.com</Anchor>
        </Caption>
      </Meta>
    </LeadContent>

    <Row>
      <Caption as="h2">Radda Radda</Caption>
    </Row>
  </Layout>
)

export default NotFoundPage

const LeadContent = styled.div`
  align-items: center;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
`;

const Meta = styled.div`
  margin-top: 2px;
  width: 100%;

  ${media.tablet`
    flex-basis: calc(100% * (5 / 8));
    max-width: calc(100% * (5 / 8));
    margin-top: 0;
  `}

  ${media.tabletLarge`
    flex-basis: calc(100% * (6 / 12));
    max-width: calc(100% * (6 / 12));
  `}

  &:last-of-type {

    ${media.tablet`
      flex-basis: initial;
      max-width: initial;
      margin-left: auto;
      width: fit-content;
    `}

    ${media.tabletLarge`
    `}
  }

  h2 {

    ${media.tablet`
      margin-top: 6px;
    `}
  }
`;

const Row = styled.div`
  bottom: 80px;
  position: absolute;
  width: fit-content;
  
  ${media.tablet`
    width: calc(100% - 80px);
  `}

  ${media.tabletLarge`
    width: calc(100% - 120px);
  `}
`;